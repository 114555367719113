$itemColor: #ffffff;
$itemBorder: #ffffff;
$itemProsColor: #00ff00;
$itemConsColor: #ff0000;
$itemNoteColor: #0000ff;
$trashIconColor: #ff0000;

$inputErrorColor: #ff0000;

$summary-footer-shadow: 0px 20px 20px 20px $shadowColor;

.pros-cons-summary-container {
  background: $summaryBg;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .pros-cons-summary-lists {
    flex-grow: 1;
    overflow: scroll;
    padding-bottom: 150px;
    .pros-cons-list {
      padding: 15px;
      .pros-cons-list-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &.pro {
        .pros-cons-list-heading {
          color: $itemProsColor;
        }
        .icon {
          color: $itemProsColor;
        }
      }
      &.con {
        .icon {
          color: $itemConsColor;
        }
        .pros-cons-list-heading {
          color: $itemConsColor;
        }
      }
      &.note {
        .icon {
          color: $itemNoteColor;
        }
        .pros-cons-list-heading {
          color: $itemNoteColor;
        }
      }
      ul {
        padding: 0;
        margin: 0;
        .pros-cons-list-item {
          color: $itemColor;
          border: 1px solid $itemBorder;
          list-style-type: none;
          margin-top: 8px;
          padding: 5px;
          display: flex;
          align-items: center;
          transition: 0.4s;
          animation: itemAddAnimation 0.3s linear;
          &.closing {
            transform: translateX(100%) scale(0);
            animation: itemClosingAnimation 0.3s linear;
          }
          .icon {
            margin-right: 5px;
          }
          &:first-child {
            margin-top: 0;
          }
          button {
            background: none;
            border: none;
            cursor: pointer;
            margin-left: auto;
            svg {
              color: $trashIconColor;
            }
          }
        }
      }
    }
  }
  .pros-cons-summary-footer {
    position: absolute;
    background: $summaryBg;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: $summary-footer-shadow;
    padding: 15px;
  }
}

.pros-cons-reduced-summary {
  padding: 5px;
  background: $summaryBg;
  display: flex;
  flex-direction: column;
  width: 40px;
  .count {
    padding: 15px;
    font-size: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &.pro {
      color: $itemProsColor;
    }
    &.con {
      color: $itemConsColor;
    }
    &.note {
      color: $itemNoteColor;
    }
  }
}

.input-error-message {
  color: $inputErrorColor;
  font-size: 12px;
}

@keyframes itemClosingAnimation {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-30%) scale(1);
  }
  100% {
    transform: translateX(100%) scale(0);
  }
}

@keyframes itemAddAnimation {
  0% {
    transform: translateX(100%) scale(0);
  }
  50% {
    transform: translateX(-30%) scale(1);
  }
  100% {
    transform: translateX(0);
  }
}

@import "variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$btn-border-radius: 4px;
$border-radius: 4px;

.btn-primary {
  background: $bg-primary;
  border: none!important;
  transition: 0.4s ease-out!important;
  &:hover, &:focus, &:active {
    transition: 0.4s ease-out!important;
    background: $bg-primary-hover!important;
  }
}

.btn {
  &.type-icon {
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/bootstrap-reboot";
@import "~bootstrap/scss/bootstrap-utilities";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/forms";